import { Box, Modal } from "@mui/material";
import { BoxStyle, DivStyle } from "./VerificationStyles";
import { CommunityScienceProcessingOutputResponseType } from "@/types/CommunityScienceSamplesApiResponseType";
import { CommunityScienceDocumentType, ICSSample, TaxonResponse } from "@/types/CommunityScienceSamplesApiResponseType";
import VerificationRecordForm from "./VerificationRecordForm";

function VerificationModal(
    props: {
        all_image_documents: Map<string,CommunityScienceDocumentType[]>,
        open: boolean,
        setOpen: (arg0: boolean) => void,
        processingOutput: CommunityScienceProcessingOutputResponseType | null,
        addProcessingOutput: (arg0: CommunityScienceProcessingOutputResponseType) => void,
        selectedTaxonResponse: TaxonResponse | null,
        sample: ICSSample
    })
{

    const handleClose = () => {
        props.setOpen(false);
    }

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
        >
            <>
                <Box sx={BoxStyle}>
                    <div style={DivStyle('5vh')}>
                        <h4 id="modal-modal-title">Creating verification record for: {props.processingOutput?.taxon_version_key || "NOT_FOUND"}</h4>
                    </div>
                    <VerificationRecordForm selectedProcessingOutput={props.processingOutput} handleClose={handleClose} addProcessingOutput={props.addProcessingOutput}/>
                </Box>
            </>
        </Modal>
    )
}

export default VerificationModal;

