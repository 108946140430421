import {TextField} from "@mui/material";
import PersonType from "@/types/PersonType";

function Person(props: {
    value: PersonType | undefined,
    prefix?: string,
    required?: boolean,
    onChange: (person: PersonType) => void
})  {
    return(
        <>
            <TextField
                required={props.required}
                name="personName"
                value={props.value?.name}
                label={(props.prefix || '') + " Name"}
                variant="outlined"
                onChange = {(e) => props.onChange({name: e.currentTarget.value, email: props.value?.email})}
                fullWidth
            />
            <TextField
                name="personEmail"
                value={props.value?.email}
                label={(props.prefix || '') + " Email"}
                variant="outlined"
                onChange = {(e) => props.onChange({name: props.value?.name || "", email: e.currentTarget.value})}
                fullWidth
            />
        </>
    )
}

export default Person;