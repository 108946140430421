import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Autocomplete, TextField} from "@mui/material";
import SamplingProtocolType from "@/types/SamplingProtocolType";
import {fetchSamplingProtocols} from "../../services/SamplingProtocolService";


function SamplingProtocol(props: { value: string, handleChange: (samplingProtocol: SamplingProtocolType | null) => void , helperText?: string}) {
    const [samplingProtocols, setSamplingProtocols] = useState<Array<SamplingProtocolType>>([]);
    const [errors, setErrors] = useState<Array<string | undefined>>([]);

    const getAllSamplingProtocols = async () => {
        try {
            const samplingProtocols = await fetchSamplingProtocols()
            setSamplingProtocols(samplingProtocols)
        } catch (err: any) {
            setErrors(errors => [...errors, err.toString()])
        }
    }

    useEffect(() => {
        getAllSamplingProtocols()
    }, [])


    const handleSamplingProtocolChange = (e: React.SyntheticEvent, value: string | null) => {
        const samplingProtocol = samplingProtocols.find(p => p.samplingProtocolName === value)
        props.handleChange(samplingProtocol || null)
    }

    const samplingProtocolNames = samplingProtocols.map((samplingProtocol) => samplingProtocol.samplingProtocolName)

    return (
        <>
            {errors.length > 0 && <Alert severity="error">
                <AlertTitle>Lookup sampling protocol error(s):</AlertTitle>
                <ul>
                    {errors.map((error, index) => {
                        return (<li key={index}>{error}</li>)
                    })}
                </ul>
            </Alert>}
            <Autocomplete
                id="sampling-protocol"
                value={props.value}
                onChange={handleSamplingProtocolChange}
                disablePortal
                options={samplingProtocolNames}
                renderInput={(params) => <TextField {...params} label="Sampling Protocol" helperText={props.helperText}/>}
                fullWidth
            />
        </>
    )
}

export default SamplingProtocol;