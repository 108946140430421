import { Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { getIndividualCSSample, postCommunityScienceProcessingOutput } from '@/services/apiCalls';
import { useParams } from 'react-router-dom';
import {
    ICSSample,
    TaxonResponse,
    CommunityScienceDocumentType,
    CommunityScienceProcessingOutputResponseType
} from '@/types/CommunityScienceSamplesApiResponseType';
import ImagePreviewModal from '@/components/community-science/ImagePreviewModal';
import DocumentList from '@/components/community-science/DocumentList';
import VerificationModal from '@/components/community-science/VerificationModal';
import VerificationAccordion from "@/components/community-science/VerificationAccordion";
import { documentImageFilter } from "@/components/common/Functions";
import IndividualTaxonResponse from "@/components/community-science/IndividualTaxonResponse";

function ViewIndividualCSSample() {
    const [errors, setErrors] = useState<Array<string>>([]);
    const [sample, setSample] = useState<ICSSample | null>(null);
    const [selectedTaxonResponse, setSelectedTaxonResponse] = useState<TaxonResponse | null>(null);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageModalSelectedIndex, setImageModalSelectedIndex] = useState<number>(0);
    const [imageModalDocuments, setImageModalDocuments] = useState<CommunityScienceDocumentType[] | null>(null);
    const all_image_documents = new Map<string, CommunityScienceDocumentType[]>();
    const [verifiedImages, setVerifiedImages] = useState<Array<string>>([]);
    const { sampleId: sample_id } = useParams<{ sampleId: string }>();
    const [processingOutput, setProcessingOutput] = useState<CommunityScienceProcessingOutputResponseType | null>(null);
    const [processingOutputTVKMap, setProcessingOutputTVKMap] = useState<Map<string, Array<CommunityScienceProcessingOutputResponseType>>>(new Map());
    const [title, setTitle] = useState<String>("");

    useEffect(() => {
        if (sample === null) {
            try {
                getIndividualCSSample(sample_id!).then((selected_sample: ICSSample) => {
                    setSample(selected_sample);
                    setTitle(selected_sample.last_name!+ ", "+ selected_sample.first_name!);
                });
            } catch (err: any) {
                setErrors([...errors, err.toString()])
            }
        }
    }, [errors, sample, sample_id])
    
    const triggerImageModal = (image_document_list: CommunityScienceDocumentType[], selected_taxon_response: TaxonResponse, selected_index?: number) => {
        setSelectedTaxonResponse(selected_taxon_response);
        setImageModalDocuments(image_document_list);
        if(selected_index !== undefined){
            setImageModalSelectedIndex(selected_index);
        }
        setImageModalOpen(true);
    }


    const renderFilesAsDownloadLinks = (taxon_response: TaxonResponse) => {
        let image_list: CommunityScienceDocumentType[] = [];
        let file_list: CommunityScienceDocumentType[] = [];
        if (taxon_response.document_list) {
            taxon_response.document_list.forEach((document: CommunityScienceDocumentType) => {
                if (!documentImageFilter(document)) {
                    file_list.push(document);
                } else {
                    image_list.push(document);
                    all_image_documents.set(taxon_response.taxon_version_key, [...all_image_documents.get(taxon_response.taxon_version_key) || [], document]);
                }
            });
        }
        return (
            <>
                <DocumentList file_list={file_list} image_list={image_list} triggerImageModal={triggerImageModal} selected_taxon_response={taxon_response}/>
            </>
        )
    }

    const createVerificationRecord = (taxon_response: TaxonResponse) => {
        if(sample !== null && taxon_response !== null){
            postCommunityScienceProcessingOutput({
                taxon_name: taxon_response.taxon_name,
                taxon_version_key: taxon_response.taxon_version_key,
                sample_date: sample.sample_date,
                sample_start_datetime: sample.sample_start_datetime,
                sample_end_datetime: sample.sample_end_datetime,
                sample_id: sample.id,
                survey_id: sample.survey_id,
                sample_location: sample.sample_location,
            }).then((createdProcessingOutput: CommunityScienceProcessingOutputResponseType) => {
                setProcessingOutput(createdProcessingOutput);
                setVerificationModalOpen(true);
            })
        }
    };

    const triggerVerificationModal = () =>
    {
        setVerificationModalOpen(true);
    }

    const setProcessingOutputList = (tvk: string, processing_output_list: CommunityScienceProcessingOutputResponseType[]) => {
        setProcessingOutputTVKMap(new Map(processingOutputTVKMap.set(tvk, processing_output_list)));
    }

    const addProcessingOutput = (processing_output: CommunityScienceProcessingOutputResponseType) => {
        let newProcessingOutputList = [...processingOutputTVKMap.get(processing_output.taxon_version_key) || []].filter((existingProcessingOutput) => existingProcessingOutput.id !== processing_output.id);
        newProcessingOutputList.push(processing_output);
        setProcessingOutputTVKMap(new Map(processingOutputTVKMap.set(processing_output.taxon_version_key, newProcessingOutputList)));
    }


    const renderTaxonResponsesToTables = (taxonResponses: TaxonResponse[]) => {
        if (taxonResponses === undefined) {
            return (<></>);
        }
        return taxonResponses.map((taxon_response: TaxonResponse, index) => {
            return (
                <IndividualTaxonResponse
                    taxon_response={taxon_response}
                    index={index}
                    renderFilesAsDownloadLinks={renderFilesAsDownloadLinks}
                    sample_id={sample_id===undefined? "":sample_id}
                    processingOutputList={processingOutputTVKMap.get(taxon_response.taxon_version_key!) || []}
                    setProcessingOutputList={setProcessingOutputList}
                    setProcessingOutput={setProcessingOutput}
                    triggerVerificationModal={triggerVerificationModal}
                    createVerificationRecord={createVerificationRecord}
                    verifiedImages={verifiedImages}
                />
            );
        });
    }

    return (
        <>
            <ImagePreviewModal 
                selectedTaxonResponse={selectedTaxonResponse!}
                open={imageModalOpen} 
                setOpen={setImageModalOpen}
                selectedIndex={imageModalSelectedIndex!}
                setSelectedIndex={setImageModalSelectedIndex}
                modalDocuments={imageModalDocuments}
                verifiedImages={verifiedImages}
            />
            <VerificationModal
                all_image_documents={all_image_documents} 
                open={verificationModalOpen} 
                setOpen={setVerificationModalOpen}
                selectedTaxonResponse={selectedTaxonResponse!}
                processingOutput={processingOutput}
                addProcessingOutput={addProcessingOutput}
                sample={sample!}
            />
            <Grid container direction="column" style={{ paddingLeft: 20 }}>
                <Grid item id="heading" mb={2}>
                    <Typography variant="h3" gutterBottom>Community Science Sample: {title} </Typography>
                </Grid>
                <Grid item><Divider sx={{ mb: 2, mt: 2 }} /></Grid>

                <VerificationAccordion
                    id={"user_info"}
                    heading={"User Information"}
                    accordionExpandText={"View user information:"}
                    questionResponses={[
                        { question_label: "First Name", question_response: sample?.first_name! },
                        { question_label: "Last Name", question_response: sample?.last_name! }
                    ]}
                />
                <VerificationAccordion
                    id={"submission_info"}
                    heading={"Submission Information"}
                    accordionExpandText={"View submission information"}
                    questionResponses={[
                        { question_label: "Sample ID", question_response: sample?.id! },
                        { question_label: "Submission ID", question_response: sample?.submission_id! },
                        { question_label: "Survey ID", question_response: sample?.survey_id! },
                        { question_label: "Sample Date", question_response: sample?.sample_date! },
                        { question_label: "Sample Start Date Time", question_response: sample?.sample_start_datetime! },
                        { question_label: "Sample End Date Time", question_response: sample?.sample_end_datetime! },
                        { question_label: "Submission Date Time", question_response: sample?.submission_datetime! },
                        { question_label: "Sample Notes", question_response: sample?.sample_notes! },
                        { question_label: "Observers Type", question_response: sample?.sample_observers_type! },
                        { question_label: "Name of Site", question_response: sample?.name_of_site! },
                        { question_label: "Weather", question_response: sample?.sample_weather! }
                    ]}
                />
                <VerificationAccordion
                    id={"question_responses"}
                    heading={"Question Responses"}
                    accordionExpandText={"View question responses:"}
                    questionResponses={sample?.question_responses!}
                    otherContent={renderFilesAsDownloadLinks({taxon_name:"other", taxon_version_key:"other", observation_count:0, document_list:sample?.document_list!, question_responses:[]})}
                />

                <Grid item id="taxon_responses" mb={2}>
                    <Typography align='center' variant="h4" gutterBottom>Taxon Responses</Typography>
                    {renderTaxonResponsesToTables(sample?.taxon_responses!)}
                </Grid>
            </Grid>
        </>
    )
}

export default ViewIndividualCSSample;
