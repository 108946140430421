import {QuestionResponse} from "@/types/CommunityScienceSamplesApiResponseType";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {AccordionDetails, AccordionSummary, Divider, Grid, TableContainer, Typography} from "@mui/material";
import {CSTable} from "./ParameterValueTable";


function VerificationAccordion(
    props: {
        id: string
        heading: string
        accordionExpandText: string
        questionResponses: QuestionResponse[]
        otherContent?: JSX.Element
    }){
    return(
        <>
            <Grid item id={props.id} mb={2}>
                <Typography align='center' variant="h4" gutterBottom>{props.heading}</Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        style={{
                            backgroundColor: "#e0e0e0",
                            fontWeight: "bold",
                            fontSize: "20px",
                            borderRadius: "5px",
                            marginBottom: "10px"
                        }}
                    >
                        <Typography>{props.accordionExpandText}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer sx={{maxHeight: 440}}>
                            <CSTable question_responses={props.questionResponses}/>
                            {props.otherContent}
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item><Divider sx={{ mb: 2, mt: 2 }} /></Grid>
        </>
    )
}

export default VerificationAccordion;