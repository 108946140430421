import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { QuestionResponse } from "@/types/CommunityScienceSamplesApiResponseType";

export function ViewQuestionResponses(props: { value: QuestionResponse[] | undefined }) {
    return (
        <>
        <Typography variant="overline" display="block">Question Responses</Typography>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell><strong>Question</strong></TableCell>
                <TableCell><strong>Response</strong></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.value?.map((row) => (
                <TableRow
                    key={row.question_label}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                    {row.question_label}
                    </TableCell>
                    <TableCell component="th" scope="row">
                    {row.question_response}
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}