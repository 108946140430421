import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Autocomplete, TextField} from "@mui/material";
import {Sensor} from "@/types/AcousticsAPIType";
import {getSensors} from "../../../services/apiCalls";
import {UserConfigType} from "./user-configs/UserConfigType";

function AudioSensors(
    props: {
        value: string | null,
        handleChange: (sensor: Sensor | null) => void
        username: string | null
        envUserConfig: UserConfigType[] | null
    }
) {
    const [sensors, setSensors] = useState<Array<Sensor>>([]);
    const [errors, setErrors] = useState<Array<string | undefined>>([]);
    const [sensorNames, setSensorNames] = useState<Array<string>>([]);

    async function getAllSensors() {
        try {
            return await getSensors();
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    const setExternalUserSensorsAndSensorNames = (username: string, sensorsList: Sensor[]) => {
        let userConfig = props.envUserConfig!.find(user => {
            return user.username === username
        })
        if (userConfig === undefined || sensorsList.length === 0) {
            return
        }
        let userConfigSensorIds: string[] = userConfig.sensor_ids;
        let userSensors: Sensor[] = sensorsList.filter(
            sensor => userConfigSensorIds.some((sensorId) => sensor.id === sensorId)
        );
        setSensorsAndSensorNames(userSensors);
    }

    const handleSensorsAvailableToUser = () => {
        getAllSensors()
            .then(sensors => {
                props.username == null ?
                    setSensorsAndSensorNames(sensors!) : setExternalUserSensorsAndSensorNames(props.username!, sensors!);
            })
    }

    const setSensorsAndSensorNames = (sensorList: Sensor[]) => {
        let sensorNamesList: string[] = [];
        sensorList.forEach(value => {
            sensorNamesList.push(value.sensor_name)
        });
        setSensors(sensorList);
        setSensorNames(sensorNamesList);
    }

    const handleSensorChange = (e: React.SyntheticEvent, value: string | null) => {
        const sensor = sensors.find(p => p.sensor_name === value)
        props.handleChange(sensor || null)
    }

    useEffect(() => {
        handleSensorsAvailableToUser()
    }, [])

    return (
        <>
            {errors.length > 0 && <Alert severity="error">
                <AlertTitle>Lookup sensors error(s):</AlertTitle>
                <ul>
                    {errors.map((error, index) => {
                        return (<li key={index}>{error}</li>)
                    })}
                </ul>
            </Alert>}
            <Autocomplete
                id={"sensor"}
                value={props.value}
                onChange={handleSensorChange}
                disablePortal
                options={sensorNames.sort((a: any, b: any) => -b.localeCompare(a))}
                renderInput={(params) => <TextField {...params} required label="Sensor"/>}
                fullWidth
            />
        </>
    )
}

export default AudioSensors;