import {getAllProjects} from "./apiCalls";
import ProjectType from "../types/ProjectType";
import {IProject} from "../types/ProjectsApiResponseType";
import APIResponseType from "../types/APIResponseType";
import {CreateProjectType} from "../types/CreateProjectType";
import {RawAxiosResponseHeaders} from "axios";
import {getAxiosAuth} from "./axiosWrapper";

const projectNameAndId = (apiResponse: Array<IProject>) => {
    return apiResponse.reduce((arr: Array<ProjectType>, currentValue: IProject) => {
        arr.push({
            projectName: currentValue.project_name,
            id: currentValue.id
        })

        return arr;
    }, [])
}

export async function fetchProjects(): Promise<Array<ProjectType>> {
    const projectApiResponse = await getAllProjects()

    return (projectNameAndId(projectApiResponse));
}

export async function postProject(payload: CreateProjectType): Promise<RawAxiosResponseHeaders> {
    try {
        const response = await (await getAxiosAuth()).post(`/metadata-app/projects`, payload)
        return response.headers
    } catch (e) {
        throw e
    }
}

function getMessages(err: any) {
    return err.response.data.violations.map((violation: any) => {
        return violation.field.replace("create.project.", "") + " " + violation.message
    });
}

export async function createProject(formData: any): Promise<APIResponseType<void>> {
    const payload: CreateProjectType = {
        project_name: formData.projectName,
        project_description: formData.description,
        start_date: formData.startDate,
        end_date: formData.endDate,
        doi: formData.doi,
        project_owner: formData.projectOwner,
        team_members: formData.teamMembers,
        funding_organisation: formData.fundingOrganisation,
        partner_organisations: formData.partnerOrganisations,
        project_documents: formData.projectDocuments,
        licenses: formData.licenses,
        funding_structure: formData.fundingStructure
    }

    try {
        await postProject(payload)
        return {isSuccessful: true}
    } catch (err: any) {
        const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)
        return {isSuccessful: false, errorMessages: errorMessages}
    }
}