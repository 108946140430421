import { Button } from "@mui/material";
import { CommunityScienceDocumentType, TaxonResponse } from "@/types/CommunityScienceSamplesApiResponseType";
import DownloadChip from "./DownloadChip";
import { DivStyle } from "./VerificationStyles";

function DocumentList(props: {
    image_list: CommunityScienceDocumentType[],
    file_list: CommunityScienceDocumentType[],
    triggerImageModal: (image_document_list: CommunityScienceDocumentType[], selected_taxon_response: TaxonResponse, selected_index?: number) => void,
    selected_taxon_response: TaxonResponse
}) {
    let image_documents: JSX.Element[] = [];
    let other_documents: JSX.Element[] = [];
    if (props.image_list) {
        props.image_list.forEach((document: CommunityScienceDocumentType, index: number) => {
            image_documents.push(<Button sx={{margin: '2vh', maxWidth: '80%', overflowX: 'auto'}} variant='contained' key={"button_" + index} onClick={() => props.triggerImageModal(props.image_list, props.selected_taxon_response, index)}>{document.filename}</Button>);
        });
    }
    if (props.file_list) {
        props.file_list.forEach((document: CommunityScienceDocumentType, index: number) => {
            other_documents.push(<DownloadChip key={index} title={document.filename} link={document.link} />);
        });
    }
    return (
        <>
            <div style={DivStyle("4vh")}>
                {other_documents}
            </div>
            <div style={DivStyle("4vh")}>
                {image_documents}
            </div>
        </>
    )
}

export default DocumentList;