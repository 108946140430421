import {
    CommunityScienceDocumentType,
    CommunityScienceVerificationCompletionStatusEnum
} from "@/types/CommunityScienceSamplesApiResponseType";
import {Tooltip, Typography} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import React from "react";
import BlockIcon from "@mui/icons-material/Block";

export function documentImageFilter(document: CommunityScienceDocumentType){
    return document.filename.match(/\.(jpg|jpeg|png|gif|bmp)$/i);
}

export const renderVerificationCompletionStatus = (comment: string, verificationCompletionStatusEnum: CommunityScienceVerificationCompletionStatusEnum) => {
    if(verificationCompletionStatusEnum === CommunityScienceVerificationCompletionStatusEnum.COMPLETE) {
        return (
                <Typography style={{display:"flex", alignContent: "center", justifyContent:"center", padding:"5px 0px"}}>
                    {comment}
                    {
                        <Tooltip title="COMPLETE">
                            <VerifiedIcon color="primary" height={200} ></VerifiedIcon>
                        </Tooltip>
                    }
                </Typography>
        )
    }
    else if(verificationCompletionStatusEnum === CommunityScienceVerificationCompletionStatusEnum.IN_PROGRESS) {
        return (
            <Typography style={{display:"flex", alignContent: "center", justifyContent:"center", padding:"5px 0px"}}>
                {comment}
                {
                    <Tooltip title="IN_PROGRESS">
                        <HourglassTopIcon height={200} ></HourglassTopIcon>
                    </Tooltip>
                }
            </Typography>
        )
    }
    else if(verificationCompletionStatusEnum === CommunityScienceVerificationCompletionStatusEnum.NOT_IN_PROGRESS) {
        return (
            <Typography style={{display:"flex", alignContent: "center", justifyContent:"center", padding:"5px 0px"}}>
                {comment}
                {
                    <Tooltip title="NOT_IN_PROGRESS">
                        <HourglassEmptyIcon height={200}></HourglassEmptyIcon>
                    </Tooltip>
                }
            </Typography>
        )
    }
    else{
        return (
                <Typography style={{display:"flex", alignContent: "center", justifyContent:"center", padding:"5px 0px"}}>
                    {comment}
                    {
                        <Tooltip title="NOT_REQUIRED">
                            <BlockIcon height={200}></BlockIcon>
                        </Tooltip>
                    }
                </Typography>
        )
    }

}
