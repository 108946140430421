import { Link, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logo from "nhm-logo.svg";
import { Box } from '@mui/material';

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

export default function SimpleLayout() {
  return (
    <>
      <StyledHeader>
        <Link to="/">
          <Box component="img" sx={{ height: 35 }} alt="logo" src={logo} id="logo" />
        </Link>
      </StyledHeader>

      <Outlet />
    </>
  );
}
