import {TextField} from "@mui/material";
import DocumentType from "@/types/DocumentType";

function Document(props: {
    value: DocumentType | undefined,
    prefix?: string,
    required?: boolean,
    onChange: (document: DocumentType) => void
})  {
    return(
        <>
            <TextField
                name="documentTitle"
                value={props.value?.title}
                label={(props.prefix || '') + " Title"}
                variant="outlined"
                onChange = {(e) => props.onChange({title: e.currentTarget.value, link: props.value?.link || ""})}
                fullWidth
            />
            <TextField
                required={props.required}
                name="documentLink"
                value={props.value?.link}
                label={(props.prefix || '') + " Link"}
                variant="outlined"
                onChange = {(e) => props.onChange({title: props.value?.title || "", link: e.currentTarget.value})}
                fullWidth
            />
        </>
    )
}

export default Document;