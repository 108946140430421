import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Autocomplete, TextField} from "@mui/material";
import SurveyType from "types/SurveyType";
import {fetchSurveysByProjectId} from "@/services/SurveyService";
import ProjectType from "types/ProjectType";

function Survey(props: { value: string | null, handleChange: (survey: SurveyType | null) => void, project: ProjectType | null }) {
    const [surveys, setSurveys] = useState<Array<SurveyType>>([]);
    const [errors, setErrors] = useState<Array<string | undefined>>([]);

    const getAllSurveys = async (projectId: string) => {
        try {
            const surveys = await fetchSurveysByProjectId(projectId)
            setSurveys(surveys)
            setErrors([])
        } catch (err: any) {
            setErrors([...errors, err.toString()])
        }
    }

    const handleSurveyChange = (e: React.SyntheticEvent, value: string|null) => {
        const survey = surveys.find(p => p.surveyName === value)
        props.handleChange(survey || null)
    }

    useEffect(() => {
        setSurveys([])
        props.handleChange(null)

        if(props.project != null) {
            getAllSurveys(props.project.id)
        }
    }, [props.project])

    const surveyNames = surveys.map((survey) => survey.surveyName)

    return (
        <>
            {errors.length > 0 && <Alert severity="error">
                <AlertTitle>Lookup survey error(s):</AlertTitle>
                <ul>
                    {errors.map((error, index) => {
                        return (<li key={index}>{error}</li>)
                    })}
                </ul>
            </Alert>}
            <Autocomplete
                id={"survey"}
                value={props.value}
                onChange={handleSurveyChange}
                disablePortal
                options={surveyNames.sort((a:any, b:any) => -b.localeCompare(a))}
                renderInput={(params) => <TextField {...params} required label="Survey"/>}
                fullWidth
            />
        </>
    )
}

export default Survey;