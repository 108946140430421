import Papa, {ParseResult} from "./PapaParsePromise";
import {getSampleIdMapForSurveyId} from "./SampleService";
import {getAllProcessingOutputs, postMedia, postProcessingOutput, uploadFile} from "./apiCalls";
import DocumentType from "../types/DocumentType";
import APIResponseType from "../types/APIResponseType";
import AttachmentType from "../types/AttachmentType";
import SampleIdMap from "../types/SampleIdMap";
import CreateProcessingOutputFromForm from "../types/CreateProcessingOutputFromForm";
import ProcessingOutputType from "../types/ProcessingOutputType";

export async function fetchProcessingOutputs(): Promise<Array<ProcessingOutputType>> {
    const processingOutputResponse = await getAllProcessingOutputs()

    return processingOutputResponse.reduce((arr: any, currentValue: any) => {
        if (currentValue.attachments) {
            arr.push({
                id: currentValue.id,
                fileName: currentValue.attachments[0].filename
            })

        }

        return arr;
    }, [])
}

export async function createAndUploadProcessingOutput({
                                                          survey,
                                                          file,
                                                          columnRange,
                                                          processingProtocol,
                                                          dateOfProcessing,
                                                          timeOfProcessing,
                                                          linkToFASTQFiles
                                                      }: CreateProcessingOutputFromForm) {
    const sampleIdsResponse = await getSampleIds(file, `${columnRange.start + 1}-${columnRange.end + 1}`, survey.id)

    if (!sampleIdsResponse.isSuccessful) {
        return sampleIdsResponse
    }

    const sampleIds = sampleIdsResponse.successData

    if (!sampleIds) {
        return {
            isSuccessful: false,
            title: "Error looking up sampleIds"
        }
    }

    const attachmentUrlResponse = await uploadAttachment(file);
    if (!attachmentUrlResponse.isSuccessful) {
        return attachmentUrlResponse
    }
    const attachmentUrl = attachmentUrlResponse.successData!
    return await create({
        processingProtocolId: processingProtocol.id,
        sampleIds: sampleIds,
        dateOfProcessing: dateOfProcessing.format("YYYY-MM-DD"),
        timeOfProcessing: timeOfProcessing ? timeOfProcessing.format("HH:mm") : null,
        attachments: [{
            title: "processed outputs results",
            link: attachmentUrl,
            filename: file.name
        }],
        listOfLinks: [{
            title: "FASTQ Files Folder",
            link: linkToFASTQFiles,
        }]
    })
}

async function getSampleIds(file: File, columnRange: string, surveyId: string): Promise<APIResponseType<Array<string>>> {
    const physicalReferenceIds = await parsePhysicalSampleReferenceIds(file, columnRange)

    return await translatePhysicalSampleReferenceIds(physicalReferenceIds, surveyId)
}

async function uploadAttachment(file: File): Promise<APIResponseType<string>> {
    try {
        const {upload_url, download_url} = await postMedia({filename: file.name});
        await uploadFile(file, {url: upload_url})
        return {
            isSuccessful: true,
            successData: download_url,
        }
    } catch (e: any) {
        return {
            isSuccessful: false,
            title: "Error: failed to upload file",
            errorMessages: ["Error: failed to upload file"],
        }
    }
}

type CreateProcessingOutputCamelCaseType = {
    processingProtocolId: string,
    sampleIds: string[],
    dateOfProcessing: string,
    timeOfProcessing: string | null,
    attachments: Array<AttachmentType>,
    listOfLinks: Array<DocumentType>
}

async function create(processingOutput: CreateProcessingOutputCamelCaseType): Promise<APIResponseType<void>> {

    const processingProtocolRequestPayload = {
        processing_protocol_id: processingOutput.processingProtocolId,
        sample_ids: processingOutput.sampleIds,
        date_of_processing: processingOutput.dateOfProcessing,
        time_of_processing: processingOutput.timeOfProcessing,
        attachments: processingOutput.attachments,
        list_of_links: processingOutput.listOfLinks
    }

    try {
        await postProcessingOutput(processingProtocolRequestPayload)
        return {
            isSuccessful: true,
            title: "Upload Successful (linked to " + processingProtocolRequestPayload.sample_ids.length + " samples)",
        }
    } catch (err: any) {
        const response = {
            isSuccessful: false,
            title: "Bio informatica upload error"
        }

        if (err.response.status === 400) {
            const errorMessages = err.response.data.detail ? [err.response.data.detail] : getMessages(err)

            return {
                ...response,
                errorMessages: errorMessages
            };
        } else {
            return {
                ...response,
                errorMessages: [err.message]
            }
        }
    }
}

function getMessages(err: any) {
    return err.response.data.violations.map((violation: any) => {
        return violation.message
    });
}

async function parsePhysicalSampleReferenceIds(file: File, columnRange: string) {
    const results: ParseResult<string> = await Papa.parseFirstLine(file);

    const parsedColumnRange = parseColumnRange(columnRange);

    return results.data.slice(parsedColumnRange.start, parsedColumnRange.end)
}

function parseColumnRange(columnRange: string) {
    const columnRangeArray = columnRange.split("-");

    const start = Number(columnRangeArray[0]) - 1;
    const end = Number(columnRangeArray[1]);
    if (start >= 0 && end >= start) {
        return {
            start: start,
            end: end
        }
    } else {
        throw new Error("Sample Columns range error")
    }
}

async function translatePhysicalSampleReferenceIds(physicalIds: string[], surveyId: string): Promise<APIResponseType<Array<string>>> {
    const sampleIdMapResponse = await getSampleIdMapForSurveyIdResponse(surveyId)

    if (!sampleIdMapResponse.isSuccessful) {
        return {
            ...sampleIdMapResponse,
        } as APIResponseType<Array<string>>
    }

    const sampleIdMap = sampleIdMapResponse.successData!

    const translations = physicalIds.map((physicalId) => {
        return {
            physicalId: physicalId,
            sampleId: sampleIdMap[physicalId],
        }
    });
    const failedTranslations = translations.filter(({sampleId}) => !sampleId);
    if (failedTranslations.length > 0) {
        return {
            isSuccessful: false,
            title: "Error parsing CSV values",
            errorMessages: failedTranslations.map(({physicalId}) => `Cannot find sample id for physical sample reference id ${physicalId}`)
        }
    } else {
        return {
            isSuccessful: true,
            successData: translations.map(({sampleId}) => sampleId)
        }
    }
}

async function getSampleIdMapForSurveyIdResponse(surveyId: string): Promise<APIResponseType<SampleIdMap>> {
    try {
        return {
            isSuccessful: true,
            successData: await getSampleIdMapForSurveyId(surveyId)
        }
    } catch (e: any) {
        return {
            isSuccessful: false,
            title: "Error parsing CSV values",
            errorMessages: [e.message]
        }
    }
}